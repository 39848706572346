/**
 * Created
 *
 * @2019/07/24
 */

import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import CourseSlider from "../components/CourseSlider"
import ProjectSlider from "../components/ProjectsSliders"
import TrialSection from "../components/TrialSection"

const openCalendly = slug => {
  window.Calendly.initPopupWidget({
    url: `https://calendly.com/codingchamps/${slug}`,
  })
}

const IndexPage = () => (
  <Layout activeMenu="index">
    <SEO title="Home" />

    <div className="banner_w3lspvt position-relative">
      <div className="container">
        <div className="d-md-flex">
          <div className="w3ls_banner_txt">
            <h3
              className="w3ls_pvt-title"
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="1000"
              data-sal-easing="ease-out-back"
            >
              Let’s turn your kid’s screen time into
              <br />
              <span>
                Coding Time{" "}
                <span role="img" aria-label="rocket and laptop emojis">
                  {" "}
                  🚀💻{" "}
                </span>
              </span>
            </h3>
            <p
              className="text-sty-banner"
              data-sal="fade"
              data-sal-delay="200"
              data-sal-duration="1000"
              data-sal-easing="ease-out-back"
            >
              Coding Champs is an online coding school that provides a safe and
              fun platform for kids of age 5-16 to learn to code and prepare
              them for the future of technology with our interactive courses
              ranging from beginner level to advance level.{" "}
            </p>
            <div
              className="row"
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="1000"
              data-sal-easing="ease-out-back"
            >
              <a
                onClick={e => openCalendly("")}
                style={{ marginLeft: `40px` }}
                rel="noopener noreferrer"
                className="btn button-orange mt-md-5 mt-4 read-more text-white"
              >
                Book a FREE Trial Class
              </a>
            </div>
          </div>
          <div className="banner-img w-75 text-center">
            <img src="images/front.png" alt="" className="img-fluid" />
          </div>
        </div>
      </div>

      <div className="icon-effects-w3l">
        <img
          src="images/shape1.png"
          alt=""
          className="img-fluid shape-wthree shape-w3-one"
        />
        <img
          src="images/shape2.png"
          alt=""
          className="img-fluid shape-wthree shape-w3-two"
        />
        <img
          src="images/shape3.png"
          alt=""
          className="img-fluid shape-wthree shape-w3-three"
        />
        <img
          src="images/shape5.png"
          alt=""
          className="img-fluid shape-wthree shape-w3-four"
        />
        <img
          src="images/shape4.png"
          alt=""
          className="img-fluid shape-wthree shape-w3-five"
        />
        <img
          src="images/shape6.png"
          alt=""
          className="img-fluid shape-wthree shape-w3-six"
        />
      </div>
    </div>

    <div className="what bg-li py-5" id="what">
      <div className="container py-xl-5 py-lg-3">
        <h3
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="1500"
          data-sal-easing="ease-out-back"
          className="tittle text-center font-weight-bold"
        >
          Our <span className="title-span">Courses</span>
        </h3>
        <p
          className="mt-4 text-center"
          // data-sal="slide-up"
          // data-sal-delay="200"
          // data-sal-duration="1500"
          // data-sal-easing="ease-out-back"
        >
          At Coding Champs, we offer fun, interactive, and hands-on coding
          courses designed to spark creativity and problem-solving skills in
          young minds! 🧠 Whether your child is a beginner or looking to
          advance, our structured programs provide the perfect learning
          experience to help them thrive in the world of technology.
        </p>
        <p className="mt-4 text-center">
          <b>
            <i>
              No prior experience needed—just curiosity and a love for learning!
            </i>
          </b>
        </p>
        <CourseSlider></CourseSlider>
        <div className="mt-4 text-center">
          <h5>
            Spots are filling up <span className="title-span">fast!</span> Don't
            miss your chance to experience the{" "}
            <span className="title-span">future of learning.</span> <br />
            Book your
            <span className="title-span"> FREE trial class today</span> —before
            it's too late!
          </h5>
          <div
            className="row center"
            // data-sal="slide-up"
            // data-sal-delay="200"
            // data-sal-duration="1000"
            // data-sal-easing="ease-out-back"
          >
            <a
              onClick={e => openCalendly("")}
              rel="noopener noreferrer"
              className="btn button-orange mt-md-2 read-more text-white"
            >
              Book a FREE Trial Class
            </a>
          </div>
        </div>
      </div>
    </div>

    <section className="midd-w3 py-4" id="faq">
      <div className="container py-xl-3 py-lg-3">
        <h3
          className="tittle text-center font-weight-bold"
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="1500"
          data-sal-easing="ease-out-back"
        >
          Why <span className="title-span">Coding Champs?</span>
        </h3>
        <p
          className="mt-4 text-center"
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="1500"
          data-sal-easing="ease-out-back"
        >
          We value your hard-earned money and time, therefore our goal is to
          provide the best quality of service on a limited budget to make
          education affordable and accessible for all.
        </p>
        <div className="row about-bottom-w3l text-center mt-4">
          <div className="col-lg-4 about-grid">
            <div>
              <img
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-duration="1500"
                data-sal-easing="ease-out-back"
                src="images/smart-boy.jpg"
                alt=""
                className="img-fluid w-50 m-1"
              />
              <div className="card-body">
                <h4 className="my-4">Kid's Learning & Growth</h4>
                <p>
                  To ensure kids' learning and growth we give them homework
                  after each class.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 about-grid my-lg-0 my-5">
            <div>
              <img
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-duration="1500"
                data-sal-easing="ease-out-back"
                src="images/group.jpg"
                alt=""
                className="img-fluid m-1"
                style={{ width: "330px" }}
              />
              <div className="card-body">
                <div>
                  <h4 className="my-4">1:1 Classes</h4>
                </div>
                <p>
                  Some kids learn at their own pace, therefore, we provide
                  private 1:1 classes for better learning and understanding.
                  {/* Staring from the fundamentals of python programming language (level 1) to adavnce level (level 2)  */}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 about-grid">
            <div>
              <img
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-duration="1500"
                data-sal-easing="ease-out-back"
                src="images/attention.jpg"
                alt=""
                className="img-fluid"
                style={{ width: "180px" }}
              />
              <div className="card-body">
                <div>
                  <h4 className="my-4">Indiviual Attention</h4>
                </div>
                <p>
                  To give individual attention to all of our students, we keep
                  our group class limited to 3-4 kids.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row about-bottom-w3l text-center mt-4">
          <div className="col-lg-4 about-grid">
            <div>
              <img
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-duration="1500"
                data-sal-easing="ease-out-back"
                src="images/teacher.jpg"
                alt=""
                className="img-fluid"
                style={{ width: "170px" }}
              />
              <div className="card-body">
                <h4 className="my-4">Book Meeting with Teachers</h4>
                <p>
                  Book a meeting with the Teacher to get direct feedback on your
                  kid's progress.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 about-grid my-lg-0 my-5">
            <div>
              <img
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-duration="1500"
                data-sal-easing="ease-out-back"
                src="images/coin.jpg"
                alt=""
                className="img-fluid m-1 w-50"
                // style={{ width: "330px" }}
              />
              <div className="card-body">
                <div>
                  <h4 className="my-4">Lowest Price</h4>
                </div>
                <p>
                  We offer the Lowest Price to make learning affordable to
                  everyone.
                  {/* Staring from the fundamentals of python programming language (level 1) to adavnce level (level 2)  */}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 about-grid">
            <div>
              <img
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-duration="1500"
                data-sal-easing="ease-out-back"
                src="images/siblings.jpg"
                alt=""
                className="img-fluid"
                style={{ width: "280px" }}
              />
              <div className="card-body">
                <div>
                  <h4 className="my-4">Siblings Discount</h4>
                </div>
                <p>
                  Get $50 off as a sibling discount because learning is better
                  when together.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-6 about-right-faq">
            <ul className="w3l-right-book mt-4">
              <li>We ensure kid's growth and learning.</li>
              <li>
                Both private and group classes available at affordable prices.
              </li>
              <li>Financial Aid available for Low-Income parents.</li>
              <li>Indiviual attention to students.</li>
              <li>Book meeting with Teacher to get direct Feedback.</li>
            </ul>
          </div>
          {/* <div className="col-lg-6 left-wthree-img text-right">
            <img src="images/1.png" alt="" className="img-fluid" />
          </div> 
        </div> */}
      </div>
    </section>
    <section className="banner-bottom-w3layouts bg-li  py-5" id="services">
      <div className="container py-lg-3">
        <h3
          className="tittle text-center font-weight-bold"
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="1500"
          data-sal-easing="ease-out-back"
        >
          Still <span className="title-span">unsure</span> about our Courses? 🤔{" "}
          <br />
          Explore our student’s <span className="title-span">projects</span> 🚀
        </h3>
        <ProjectSlider />
      </div>
    </section>

    <section className="banner-bottom-w3layouts py-5" id="services">
      <div className="container py-xl-5 py-lg-3">
        <h3
          className="tittle text-center font-weight-bold"
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="1500"
          data-sal-easing="ease-out-back"
        >
          Parent's <span className="title-span">Feedback</span>
        </h3>
        <p className="mt-4 text-center">
          Nothing makes us happier than seeing kids thrive and parents proud.
          Our programs are designed to spark curiosity, boost confidence, and
          create a love for learning. But don’t just take our word for it—hear
          from parents who have seen real progress in their children. Their
          happy feedback says it all!
        </p>
        <div className="row pt-lg-4 ">
          <div className="col-lg-4 text-center">
            <img
              // data-sal="slide-up"
              // data-sal-delay="200"
              // data-sal-duration="1500"
              // data-sal-easing="ease-out-back"
              src="images/test-1.png"
              alt=""
              className="img-fluid w-100 m-2"
            />
          </div>
          <div className="col-lg-4 text-center my-lg-0 my-3">
            <img
              // data-sal="slide-up"
              // data-sal-delay="200"
              // data-sal-duration="1500"
              // data-sal-easing="ease-out-back"
              src="images/test-2.png"
              alt=""
              className="img-fluid w-100 m-2"
            />
          </div>
          <div className="col-lg-4 text-center">
            <img
              // data-sal="slide-up"
              // data-sal-delay="200"
              // data-sal-duration="1500"
              // data-sal-easing="ease-out-back"
              src="images/test-3.png"
              alt=""
              className="img-fluid w-100 m-2"
            />
          </div>
        </div>
        <h4 className="tittle text-center ">
          Not sure where to{" "}
          <span className="title-span font-weight-bold">start? </span> Take our{" "}
          <span className="title-span font-weight-bold">60-Seconds Quiz</span>{" "}
          to Find the{" "}
          <span className="title-span font-weight-bold">Perfect Fit!</span>{" "}
        </h4>
        <div className="center">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScfeErYqozrNbJdCh_NiKLvPnj2nHj5rbSgC96Y5lrbviN8Fg/viewform?usp=dialog"
            target="_blank"
            rel="noopener noreferrer"
            className="btn button-orange mt-md-2 read-more text-white"
          >
            Start The Quiz
          </a>
        </div>
      </div>
    </section>
    <TrialSection />
  </Layout>
)

export default IndexPage
