import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const useWindowSize = () => {
  const [size, setSize] = React.useState([0, 0])

  React.useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])

  return size
}

const ProjectSlider = () => {
  const [width] = useWindowSize()

  // Calculate dynamic padding and margins based on screen width
  const getDynamicSpacing = () => {
    if (width <= 480) return { padding: "10px", margin: "5px" }
    if (width <= 768) return { padding: "15px", margin: "8px" }
    return { padding: "20px", margin: "10px" }
  }

  const { padding, margin } = getDynamicSpacing()

  // const [windowWidth, setWindowWidth] = React.useState(
  //   typeof window !== "undefined" ? window.innerWidth : 0
  // )

  // React.useEffect(() => {
  //   const handleResize = () => {
  //     setWindowWidth(window.innerWidth)
  //   }

  //   window.addEventListener("resize", handleResize)
  //   return () => window.removeEventListener("resize", handleResize)
  // }, [])

  const sliderRef = React.useRef(null)
  const [isInteracting, setIsInteracting] = React.useState(false)

  const play = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay()
    }
  }

  const pause = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPause()
    }
  }

  const NextArrow = ({ onClick }) => (
    <button
      onClick={onClick}
      style={{
        position: "absolute",
        right: "-10px",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 1,
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        background: "#e07845",
        color: "white",
        border: "none",
        cursor: "pointer",
        fontSize: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      ›
    </button>
  )

  const PrevArrow = ({ onClick }) => (
    <button
      onClick={onClick}
      style={{
        position: "absolute",
        left: "-10px",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 1,
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        background: "#e07845",
        color: "white",
        border: "none",
        cursor: "pointer",
        fontSize: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      ‹
    </button>
  )

  const handleInteractionStart = () => {
    setIsInteracting(true)
    pause()
  }

  const handleInteractionEnd = () => {
    setIsInteracting(false)
    play()
  }

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500, // Speed of transition when buttons are clicked
  //   slidesToShow: 2,
  //   slidesToScroll: 1,
  //   nextArrow: <NextArrow />,
  //   prevArrow: <PrevArrow />,
  //   autoplay: true, // Enable autoplay
  //   autoplaySpeed: 8000, // Time between automatic slides (in milliseconds)
  //   cssEase: "ease", // Smooth transition
  //   pauseOnHover: false,
  //   swipe: false, // Disable swipe
  //   touchMove: false, // Disable touch movement
  //   draggable: false, // Disable dragging
  //   waitForAnimate: true, // Wait for animation to complete before next slide
  //   responsive: [
  //     {
  //       breakpoint: 1200,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //         autoplay: true,
  //         autoplaySpeed: 8000,
  //         speed: 500,
  //         swipe: false,
  //         touchMove: false,
  //         draggable: false,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         arrows: true,
  //         autoplay: true,
  //         autoplaySpeed: 8000,
  //         speed: 500,
  //         swipe: false,
  //         touchMove: false,
  //         draggable: false,
  //       },
  //     },
  //   ],
  // }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 8000,
    cssEase: "ease",
    pauseOnHover: false,
    swipe: false,
    touchMove: false,
    draggable: false,
    waitForAnimate: true,
    centerMode: false, // Add this
    centerPadding: "0", // Add this
    adaptiveHeight: true, // Add this
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 8000,
          speed: 500,
          swipe: false,
          touchMove: false,
          draggable: false,
          centerMode: false,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          autoplay: true,
          autoplaySpeed: 8000,
          speed: 500,
          swipe: false,
          touchMove: false,
          draggable: false,
          centerMode: false,
          centerPadding: "0",
          adaptiveHeight: true,
        },
      },
    ],
  }

  const buttonStyle = {
    display: "inline-block",
    padding: "10px 20px",
    backgroundColor: "#e07845",
    color: "white",
    textDecoration: "none",
    borderRadius: "5px",
    transition: "background-color 0.2s",
    cursor: "pointer",
    "@media (max-width: 768px)": {
      padding: "8px 16px",
      fontSize: "0.9rem",
    },
  }

  const openCalendly = e => {
    window.Calendly.initPopupWidget({
      url: `https://calendly.com/codingchamps/`,
    })
  }

  const projects = [
    {
      title: "Obby Game by Abdullah",
      videoUrl:
        "https://www.facebook.com/plugins/video.php?height=350&href=https%3A%2F%2Fwww.facebook.com%2Fcodingchamps.ca%2Fvideos%2F621443002935048%2F&show_text=false&width=560&t=0",
      description:
        "Meet our cutest and youngest champ Abdullah 👨🏻‍💻 who just 7 years old.",
    },
    {
      title: "Python Guessing Game By Mohanad",
      videoUrl:
        "https://www.facebook.com/plugins/video.php?height=350&href=https%3A%2F%2Fwww.facebook.com%2Fcodingchamps.ca%2Fvideos%2F463207542058344%2F&show_text=false&width=560&t=0",
      description:
        "Watch our champ Mohanad Kuhail explaining and demonstrating the Number Guessing game he made.",
    },
    {
      title: "Web Portfolio by Andrew",
      videoUrl:
        "https://www.facebook.com/plugins/video.php?height=350&href=https%3A%2F%2Fwww.facebook.com%2Fcodingchamps.ca%2Fvideos%2F1016156739057090%2F&show_text=false&width=560&t=0",
      description:
        "A quick demo of the web portfolio made by our very talented 11 years old student Andrew Jacob.",
    },
    {
      title: "Holiday Theme Card by Mifrah",
      videoUrl:
        "https://drive.google.com/file/d/13yuLbMH-5OgdGozeoWgY6V1xTq1FKmWT/preview",
      description:
        "Watch the holiday card created by our 5-year-old student Mifrah.",
    },
    {
      title: "Roblox Obby Game by Akansha",
      videoUrl:
        "https://drive.google.com/file/d/19LqF1hzRvCyn_qROx3PifRbCr5cX625Y/preview",
      description:
        "Watch the Obby game built by our 10-year-old student using Roblox Studio",
    },
    {
      title: "Drawing in Python by Aaima",
      videoUrl:
        "https://drive.google.com/file/d/1dVSQlhWnynuoNPKlquzGg85wZyEyLYhH/preview",
      description:
        "Watch our student Aaima using the turtle library to draw different shapes.",
    },
    {
      title: "Todo Application by Hanna",
      videoUrl:
        "https://drive.google.com/file/d/1fKCxqICipgJdaTO8OXDWyPzKzhnvnRNt/preview",
      description:
        "Watch our 11-year-old student Hanna who created a Todo app using Python .",
    },
    {
      title: "Python Quiz Game by Saim",
      videoUrl:
        "https://drive.google.com/file/d/13LIqTm-hC1yRYxIett2u4iZ6Qpqwyhzf/preview",
      description:
        "Watch our student Saim who created a Quiz Game using Python ",
    },
    {
      title: "Table Generator in Python",
      videoUrl:
        "https://drive.google.com/file/d/1HTGdr9jiyqpz4OExYBACLQPRuUquyLuZ/preview",
      description:
        "Watch how our student Hanna wrote a Python script to generate tables ",
    },
    // Add more projects here
  ]

  const cardStyle = {
    width: "95%",
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "white",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    margin,
    padding,
  }
  // const cardStyle = {
  //   height: "500px",
  //   border: "1px solid #ddd",
  //   borderRadius: "8px",
  //   overflow: "hidden",
  //   backgroundColor: "white",
  //   boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  //   margin: "10px",
  //   padding: "20px",
  //   "@media (max-width: 768px)": {
  //     height: "400px", // Smaller height for tablets
  //     padding: "15px",
  //   },
  //   "@media (max-width: 480px)": {
  //     height: "350px", // Even smaller height for phones
  //     padding: "10px",
  //   },
  // }

  // Update title style
  const titleStyle = {
    textAlign: "center",
    // marginBottom: "20px",
    color: "#333",
    fontSize: "1.5rem",
    "@media (max-width: 768px)": {
      fontSize: "1.2rem",
      marginBottom: "15px",
    },
    "@media (max-width: 480px)": {
      fontSize: "1rem",
      marginBottom: "10px",
    },
  }

  // Update description style
  const descriptionStyle = {
    textAlign: "center",
    color: "#666",
    lineHeight: "1.6",
    margin: "15px 0",
    "@media (max-width: 768px)": {
      fontSize: "0.9rem",
      margin: "10px 0",
    },
    "@media (max-width: 480px)": {
      fontSize: "0.8rem",
      margin: "8px 0",
    },
  }

  // Update iframe container style
  const iframeContainerStyle = {
    position: "relative",
    overflow: "hidden",
    paddingTop: "56.25%",
    marginBottom: "15px",
    "@media (max-width: 768px)": {
      marginBottom: "10px",
    },
    "@media (max-width: 480px)": {
      marginBottom: "8px",
    },
  }

  const iframeStyle = {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    border: "0",
  }

  const headingBox = {
    height: "70px",
  }

  return (
    <div style={{ padding: "20px", maxWidth: "1200px", margin: "0 auto" }}>
      <Slider
        ref={sliderRef}
        {...settings}
        // key={`slider-${windowWidth <= 768 ? "mobile" : "desktop"}`}
      >
        {" "}
        {projects.map((project, index) => (
          <div
            key={index}
            style={{ padding: "0 10px" }}
            onMouseEnter={handleInteractionStart}
            onMouseLeave={handleInteractionEnd}
            onTouchStart={handleInteractionStart}
            onTouchEnd={handleInteractionEnd}
            onClick={handleInteractionStart}
          >
            <div style={cardStyle} role="button" tabIndex={0}>
              <div style={headingBox}>
                <h3 style={titleStyle}>
                  <a
                    href="# "
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                    }}
                  >
                    {project.title}
                  </a>
                </h3>
              </div>

              <div style={iframeContainerStyle}>
                <iframe
                  title={project.videoUrl}
                  src={project.videoUrl}
                  style={iframeStyle}
                  scrolling="no"
                  frameBorder="0"
                  allowFullScreen={true}
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                ></iframe>
              </div>
              <p style={descriptionStyle}>{project.description}</p>
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <a
                  onClick={openCalendly}
                  style={buttonStyle}
                  onMouseOver={e =>
                    (e.target.style.backgroundColor = "#c9643a")
                  }
                  onMouseOut={e => (e.target.style.backgroundColor = "#e07845")}
                >
                  Book a Demo Class
                </a>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default ProjectSlider
