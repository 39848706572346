import React from "react"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const CourseSlider = () => {
  // Create a ref for the slider
  const sliderRef = React.useRef(null)

  // Optional: Functions to manually control the slider
  const play = () => {
    sliderRef.current.slickPlay()
  }

  const pause = () => {
    sliderRef.current.slickPause()
  }

  const NextArrow = ({ onClick }) => (
    <button
      onClick={onClick}
      style={{
        position: "absolute",
        right: "-10px",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 1,
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        background: "#e07845",
        color: "white",
        border: "none",
        cursor: "pointer",
        fontSize: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      ›
    </button>
  )

  const PrevArrow = ({ onClick }) => (
    <button
      onClick={onClick}
      style={{
        position: "absolute",
        left: "-10px",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 1,
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        background: "#e07845",
        color: "white",
        border: "none",
        cursor: "pointer",
        fontSize: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      ‹
    </button>
  )

  const settings = {
    dots: true,
    infinite: true,
    speed: 500, // Speed of transition when buttons are clicked
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 6000, // Time between automatic slides (in milliseconds)
    cssEase: "ease", // Smooth transition
    pauseOnHover: false,
    swipe: false, // Disable swipe
    touchMove: false, // Disable touch movement
    draggable: false, // Disable dragging
    waitForAnimate: true, // Wait for animation to complete before next slide
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
          speed: 500,
          swipe: false,
          touchMove: false,
          draggable: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          autoplay: true,
          autoplaySpeed: 6000,
          speed: 500,
          swipe: false,
          touchMove: false,
          draggable: false,
        },
      },
    ],
  }

  const containerStyle = {
    padding: "20px",
    maxWidth: "1200px",
    margin: "0 auto",
    "@media (max-width: 768px)": {
      padding: "10px",
    },
  }

  const cardStyle = {
    height: "400px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    transition: "transform 0.2s",
    margin: "10px",
    "@media (max-width: 768px)": {
      height: "350px", // Smaller height on mobile
      margin: "5px",
    },
  }

  const imageContainerStyle = {
    height: "140px",
    padding: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 768px)": {
      height: "100px",
      padding: "15px",
    },
  }

  const titleStyle = {
    margin: "0 0 15px 0",
    textAlign: "center",
    fontSize: "1.5rem",
    "@media (max-width: 768px)": {
      fontSize: "1.2rem",
      margin: "0 0 10px 0",
    },
  }

  const descriptionStyle = {
    margin: "0",
    textAlign: "center",
    color: "#666",
    lineHeight: "1.5",
    "@media (max-width: 768px)": {
      fontSize: "0.9rem",
    },
  }

  const buttonStyle = {
    display: "inline-block",
    padding: "10px 20px",
    backgroundColor: "#e07845",
    color: "white",
    textDecoration: "none",
    borderRadius: "5px",
    transition: "background-color 0.2s",
    cursor: "pointer",
    "@media (max-width: 768px)": {
      padding: "8px 16px",
      fontSize: "0.9rem",
    },
  }
  const courses = [
    {
      image: "images/roblox-logo.png",
      title: "Game Development",
      description:
        "It is an introductory level course that will help kids to create and publish their first game on Roblox.com",
      link: "/courses/#roblox",
    },
    {
      image: "images/pythonlogo.png",
      title: "Python Programming",
      description:
        "This course will equip learners with essential coding skills as they explore Python Language.",
      link: "/courses/#python",
    },
    {
      image: "images/web-dev.png",
      title: "Web Development",
      description:
        "Introduction to HTML, CSS, and JavaScript to help the kids develop and host their own site",
      link: "/courses/#web",
    },
    {
      image: "/images/scratch.png",
      title: "Scratch Coding",
      description:
        " This course introduces young minds to coding through the user-friendly Scratch platform, sparking creativity and innovation.",
      link: "/courses/#scratch",
    },
    {
      image: "images/Robot.png",
      title: "Introduction to A.I (Coming Soon!)",
      description:
        "Introduction to HTML, CSS, and JavaScript to help the kids develop and host their own site",
      link: "https://forms.gle/YvKGibANcNZThJFc7",
    },
  ]

  return (
    <div style={containerStyle}>
      <Slider ref={sliderRef} {...settings}>
        {courses.map((course, index) => (
          <div
            key={index}
            style={{ padding: "0 10px" }}
            onMouseEnter={pause} // Added here
            onMouseLeave={play} // Added here
          >
            <div style={cardStyle}>
              <div style={imageContainerStyle}>
                <img
                  src={course.image}
                  alt={course.title}
                  style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div
                style={{
                  padding: "20px",
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h3 style={titleStyle}>{course.title}</h3>
                  <p style={descriptionStyle}>{course.description}</p>
                </div>
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <a
                    href={course.link}
                    target={
                      course.title === "Introduction to A.I (Coming Soon!)"
                        ? "_blank"
                        : ""
                    }
                    style={buttonStyle}
                    onMouseOver={e =>
                      (e.target.style.backgroundColor = "#c9643a")
                    }
                    onMouseOut={e =>
                      (e.target.style.backgroundColor = "#e07845")
                    }
                  >
                    {course.title === "Introduction to A.I (Coming Soon!)"
                      ? "Join Waitlist"
                      : "Read More"}
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default CourseSlider
